import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import '../../styles/cta.css';
import Top from '../../img/cta/cta-top.png';

import CtaLeft from '../../img/cta/cta_left.png';
import CtaRight from '../../img/cta/cta_right.png';


const CTA = () => {
  return (
    <>
      <div className='shape-top'>
        <img src={Top} alt='Shape Top' className='img-fluid' />
      </div>
      <MDBContainer fluid className='cta-section'>
        <div className='cta-images'>
          <img src={CtaLeft} alt='Left Decoration' className='cta-image-left' />
          <img src={CtaRight} alt='Right Decoration' className='cta-image-right' />
        </div>
        <MDBRow className='justify-content-center cta-text-row'>
          <MDBCol md='5' className='text-center'>
            <h2>Está pronto para dar o próximo passo?</h2>
            <p>Explore nossas opções de produtos e veja como podemos ajudar a sua empresa a se destacar. 
            Clique abaixo e conheça as soluções que podem fazer a diferença para o seu negócio!</p>
            <a className='cta-btn' href='/categorias'>Explorar soluções</a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default CTA;
