import React, { useRef } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import Clients from '../../components/clients'
import { RiGift2Line, RiTruckLine } from "react-icons/ri"; // Ícones da biblioteca react-icons/ri
import { HiOutlineViewGrid } from "react-icons/hi"; // Ícones da biblioteca react-icons/hi
import { AiOutlineEye } from "react-icons/ai"; // Ícones da biblioteca react-icons/ai
import { MdOutlinePrint } from "react-icons/md"; // Ícones da biblioteca react-icons/md
import { FiCheckCircle } from "react-icons/fi"; // Ícones da biblioteca react-icons/fi
import { BsPersonSquare } from "react-icons/bs"; // Ícones da biblioteca react-icons/bs
import { BiTrophy } from "react-icons/bi"; // Ícones da biblioteca react-icons/bi
import { BsArrowRight } from "react-icons/bs";
import "../../styles/about.css";

const SecondSection = () => {
  const scrollContainerRef = useRef(null);
  const reasonContainerRef = useRef(null);

  // Função para adicionar o comportamento de arrastar
  const handleMouseDown = (e, ref) => {
    ref.current.isDown = true;
    ref.current.startX = e.pageX - ref.current.offsetLeft;
    ref.current.scrollLeft = ref.current.scrollLeft || 0;
  };

  const handleMouseMove = (e, ref) => {
    if (!ref.current.isDown) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - ref.current.startX) * 2;
    ref.current.scrollLeft = ref.current.scrollLeft - walk;
  };

  const handleMouseUpOrLeave = (ref) => {
    ref.current.isDown = false;
  };

  // Função para dispositivos móveis (touch)
  const handleTouchMove = (e, ref) => {
    const touch = e.touches[0];
    const walk = (ref.current.startX - touch.pageX) * 2;
    ref.current.scrollLeft = ref.current.scrollLeft + walk;
    ref.current.startX = touch.pageX;
  };

  const services = [
    {
      icon: <RiGift2Line className="service-icon" />,
      title: "Brindes Exclusivos",
      description:
        "Criamos brindes corporativos que vão além da personalização — eles traduzem a essência da sua marca, tornando-se o diferencial em eventos e campanhas promocionais.",
    },
    {
      icon: <HiOutlineViewGrid className="service-icon" />,
      title: "Materiais de PDV",
      description:
        "Nossos materiais de ponto de venda são projetados para aumentar a visibilidade e atrair a atenção do consumidor, impulsionando as vendas e fortalecendo a imagem da sua marca.",
    },
    {
      icon: <AiOutlineEye className="service-icon" />,
      title: "Comunicação Visual",
      description:
        "Oferecemos soluções criativas e inovadoras para destacar sua empresa, com fachadas, painéis e sinalizações que chamam atenção e reforçam a identidade visual.",
    },
    {
      icon: <MdOutlinePrint className="service-icon" />,
      title: "Impressos de Alta Qualidade",
      description:
        "Nossa expertise em impressão garante resultados impecáveis em catálogos, flyers e outros materiais gráficos essenciais para suas campanhas.",
    },
  ];

  const reasons = [
    {
      icon: <FiCheckCircle className="reason-icon" />,
      title: "Compromisso com a Excelência",
      description:
        "Utilizamos a mais alta tecnologia e uma equipe qualificada para garantir resultados excepcionais em cada projeto, desde a concepção até a finalização.",
    },
    {
      icon: <BsPersonSquare className="reason-icon" />,
      title: "Foco no Cliente",
      description:
        "Nosso atendimento é personalizado para entender as necessidades de cada cliente, oferecendo soluções sob medida para seus desafios.",
    },
    {
      icon: <RiTruckLine className="reason-icon" />,
      title: "Entrega Rápida e Segura",
      description:
        "Nos orgulhamos de cumprir prazos com eficiência, sem comprometer a qualidade. Seu projeto é entregue de forma rápida e segura.",
    },
    {
      icon: <BiTrophy className="reason-icon" />,
      title: "História de Sucesso",
      description:
        "Nossa trajetória de sucesso é comprovada por um vasto portfólio e uma base de clientes que confiam no nosso trabalho para fazer suas marcas brilharem.",
    },
  ];

  return (
    <>
      {/* Seção de Serviços */}
      <MDBRow className="justify-content-evenly align-items-center tip-row">
        <MDBCol md="4" className="tip-exp">
          <h2 className="title-exp">Nossa Expertise</h2>
          <p className="description-exp">
            Somos especialistas em criar soluções impactantes que conectam marcas
            aos seus públicos. Trabalhamos desde pequenas empresas até grandes
            corporações, sempre entregando qualidade excepcional em cada
            detalhe.
          </p>
          <a href="#!" className="cta-btn-contato">
            Conheça Nossas Soluções <BsArrowRight />
          </a>
        </MDBCol>

        <MDBCol md="8">
          <div
            className="horizontal-scroll-container"
            ref={scrollContainerRef}
            onMouseDown={(e) => handleMouseDown(e, scrollContainerRef)}
            onMouseMove={(e) => handleMouseMove(e, scrollContainerRef)}
            onMouseUp={() => handleMouseUpOrLeave(scrollContainerRef)}
            onMouseLeave={() => handleMouseUpOrLeave(scrollContainerRef)}
            onTouchMove={(e) => handleTouchMove(e, scrollContainerRef)}
          >
            {services.map((service, index) => (
              <div key={index} className="service-content p-4">
                <div>
                  {service.icon}
                  <h5 className="service-title">{service.title}</h5>
                </div>
                <p className="service-description">{service.description}</p>
              </div>
            ))}
          </div>
        </MDBCol>
      </MDBRow>

      <Clients />

      {/* Seção de Razões */}
      <h2 className="title-cta-reasons">Por que Escolher a PDV em Foco?</h2>
      <p className="desc-cta-reasons">
        Descubra as vantagens que nos tornam a melhor escolha
      </p>
      {/* Subtítulo */}
      <MDBRow className="align-items-center justify-content-center reasons-row">
        <MDBCol md={8}>
          <MDBRow className="justify-content-center align-items-center">
            {reasons.map((reason, index) => (
              <MDBCol md="6" key={index} className="mb-4">
                <MDBCard className="card-reasons">
                  <MDBCardBody>
                    {" "}
                    {/* Cada coluna ocupa metade da linha */}
                    <div className="reason-content p-4">
                      {reason.icon}
                      <h5 className="reason-title">{reason.title}</h5>
                      <p className="reason-description">{reason.description}</p>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default SecondSection;
