import React, { useState, useEffect } from "react";
import "../styles/produto.css";
import logos from "../img/clients/logos.json";
import { MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";

const Ferramentas = () => {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    let tempLoadedImages = Array(logos.length).fill(false);

    logos.forEach((logo, index) => {
      const img = new Image();
      img.src = require(`../img/clients/${logo}`);
      img.onload = () => {
        tempLoadedImages[index] = true;
        setLoadedImages([...tempLoadedImages]); // Atualiza o estado com as imagens carregadas
      };
    });
  }, []);

  return (
    <MDBContainer>
      <MDBRow className="carousel-section justify-content-center align-items-center">
        <MDBCol md={6} className="carousel-text">
          <h2>Grandes Marcas, Grandes Parcerias</h2>
          <p>
            Empresas de renome confiam na PDV em Foco para entregar soluções
            visuais de alta qualidade, sempre com agilidade e precisão. Seja
            para grandes campanhas ou pequenos projetos, estamos prontos para
            superar expectativas.
          </p>
        </MDBCol>
        <MDBCol md={11}>
          <div
            className="carousel-container slider"
            style={{
              "--width": "200px",
              "--height": "350px",
              "--quantity": logos.length,
            }}
          >
            <div className="list">
              {logos.map((logo, index) => (
                <div
                  className="item"
                  style={{ "--position": index + 1 }}
                  key={index}
                >
                  {loadedImages[index] ? (
                    <img
                      src={require(`../img/clients/${logo}`)}
                      alt={`${logo.split(".")[0]} Logo`}
                    />
                  ) : (
                    <div className="placeholder">Carregando...</div> // Placeholder enquanto a imagem carrega
                  )}
                </div>
              ))}
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Ferramentas;
