import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import "../../styles/produto.css";
import { api } from "../../controllers/api-controller";
import DividerImage from "../../img/product/product-bot.png";

// Importação de ícones
import { MdOutlineDescription } from "react-icons/md";  // Ícone para o papel
import { BiRuler } from "react-icons/bi";  // Ícone para tamanho
import { TiBrush } from "react-icons/ti";  // Ícone para cores
import { IoDiamondOutline } from "react-icons/io5";  // Ícone para acabamento
import { AiOutlineMail, AiOutlinePhone, AiOutlineUser } from "react-icons/ai";  // Ícones para campos de contato
import { FiShoppingCart } from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { LuMailCheck } from "react-icons/lu";

const TopSection = ({ produto }) => {
  const produtoData = produto || {};

  const [selectedPapel, setSelectedPapel] = useState("");
  const [selectedTamanho, setSelectedTamanho] = useState("");
  const [selectedCores, setSelectedCores] = useState("");
  const [selectedAcabamento, setSelectedAcabamento] = useState("");
  const [selectedQuantidade, setSelectedQuantidade] = useState(100);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);

  // Validações
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhone = (phone) => /^\d{10,11}$/.test(phone);

  const validateSelections = () => {
    if (
      (produtoData.papel && selectedPapel === "") ||
      (produtoData.formato_tamanho && selectedTamanho === "") ||
      (produtoData.cores && selectedCores === "") ||
      (produtoData.enobrecimento_acabamento && selectedAcabamento === "")
    ) {
      alert("Por favor, selecione todas as opções ativas antes de enviar.");
      return false;
    }
    return true;
  };

  // Enviar o lead para o servidor
  const sendLeadToServer = async () => {
    const leadData = {
      nome: name,
      email: email,
      telefone: phone,
      newsletter_subscribed: subscribeNewsletter,
    };

    try {
      const response = await fetch(`${api}/leads/add`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(leadData),
      });
      if (!response.ok) console.error("Erro ao salvar lead.");
    } catch (error) {
      console.error("Erro ao enviar lead para o servidor:", error);
    }
  };

  // Geração da mensagem
  const generateMessage = () => {
    let message = `Produto: ${produtoData.nome || "Produto desconhecido"}\n`;
    if (selectedPapel) message += `Papel: ${selectedPapel}\n`;
    if (selectedTamanho) message += `Tamanho: ${selectedTamanho}\n`;
    if (selectedCores) message += `Cores: ${selectedCores}\n`;
    if (selectedAcabamento) message += `Acabamento: ${selectedAcabamento}\n`;
    message += `Quantidade: ${selectedQuantidade} unidades\n`;
    message += `\nNome: ${name}\nEmail: ${email}\nTelefone: ${phone}\n`;
    if (subscribeNewsletter) message += "Desejo se inscrever no newsletter.\n";
    return encodeURIComponent(message);
  };

  // Ações de WhatsApp e E-mail
  const handleWhatsApp = async () => {
    if (validateSelections() && validateEmail(email) && validatePhone(phone)) {
      await sendLeadToServer();
      const message = generateMessage();
      window.open(
        `https://api.whatsapp.com/send?phone=5511912800045&text=${message}`,
        "_blank"
      );
    } else {
      if (!validateEmail(email)) alert("Por favor, insira um e-mail válido.");
      if (!validatePhone(phone)) alert("Por favor, insira um telefone válido.");
    }
  };

  const handleEmail = async () => {
    if (validateSelections() && validateEmail(email) && validatePhone(phone)) {
      await sendLeadToServer();
      const message = generateMessage();
      window.open(
        `mailto:contato@pdvemfoco.com.br?subject=Cotação de Produto&body=${message}`,
        "_blank"
      );
    } else {
      if (!validateEmail(email)) alert("Por favor, insira um e-mail válido.");
      if (!validatePhone(phone)) alert("Por favor, insira um telefone válido.");
    }
  };

  return (
    <>
      <MDBContainer fluid className="top-section-container">
        <MDBRow className="align-items-center justify-content-center">
          <MDBCol md={9}>
            <MDBCard className="card-product">
              <MDBCardBody>
                <MDBRow className="justify-content-center align-items-start">
                  <MDBCol md={4} className="px-3">
                    {produtoData.url_imagem ? (
                      <img
                        src={`${api}/${
                          produtoData.url_imagem.split(",")[
                            produtoData.thumbnail_index || 0
                          ]
                        }`}
                        alt={produtoData.nome}
                        className="img-fluid product-image"
                      />
                    ) : (
                      <p>Imagem não disponível</p>
                    )}
                    <h1 className="product-title">
                      {produtoData.nome || "Produto não encontrado"}
                    </h1>
                    <div className="desc-container">
                      <h6>Descrição</h6>
                      <p className="text-muted">
                        {produto.descricao || "Descrição indisponível."}
                      </p>
                    </div>
                    <p className="product-tags">Tags: {produtoData.tags}</p>
                  </MDBCol>
                  <MDBCol md={8} className="px-3">
                    <div className="orc-container">
                      <div className="title-container">
                        <h3>Solicite seu Orçamento Personalizado</h3>
                        <p>
                          Preencha as informações abaixo e escolha a melhor forma de receber o seu orçamento. É rápido e simples!
                        </p>
                      </div>
                      <MDBRow>
                        {produtoData.papel && produtoData.papel.trim() !== "" && (
                          <MDBCol md="6" className="custom-select mb-3">
                            <label>
                              <MdOutlineDescription /> Selecione o Tipo de Papel
                            </label>
                            <select
                              value={selectedPapel}
                              onChange={(e) => setSelectedPapel(e.target.value)}
                              className="custom-input"
                            >
                              <option value="">
                                Escolha o tipo de papel
                              </option>
                              {produtoData.papel
                                .split(",")
                                .map((papelItem, index) => (
                                  <option key={index} value={papelItem.trim()}>
                                    {papelItem.trim()}
                                  </option>
                                ))}
                            </select>
                          </MDBCol>
                        )}

                        {produtoData.formato_tamanho &&
                          produtoData.formato_tamanho.trim() !== "" && (
                            <MDBCol md="6" className="custom-select mb-3">
                              <label>
                                <BiRuler /> Escolha o Formato
                              </label>
                              <select
                                value={selectedTamanho}
                                onChange={(e) =>
                                  setSelectedTamanho(e.target.value)
                                }
                                className="custom-input"
                              >
                                <option value="">
                                  Escolha o formato ideal
                                </option>
                                {produtoData.formato_tamanho
                                  .split(",")
                                  .map((tamanhoItem, index) => (
                                    <option
                                      key={index}
                                      value={tamanhoItem.trim()}
                                    >
                                      {tamanhoItem.trim()}
                                    </option>
                                  ))}
                              </select>
                            </MDBCol>
                          )}
                      </MDBRow>

                      <MDBRow className="w-100">
                        {produtoData.cores && produtoData.cores.trim() !== "" && (
                          <MDBCol md="6" className="custom-select mb-3">
                            <label>
                              <TiBrush /> Selecione as Cores
                            </label>
                            <select
                              value={selectedCores}
                              onChange={(e) => setSelectedCores(e.target.value)}
                              className="custom-input"
                            >
                              <option value="">
                                Escolha as cores que deseja
                              </option>
                              {produtoData.cores
                                .split(",")
                                .map((corItem, index) => (
                                  <option key={index} value={corItem.trim()}>
                                    {corItem.trim()}
                                  </option>
                                ))}
                            </select>
                          </MDBCol>
                        )}

                        {produtoData.enobrecimento_acabamento &&
                          produtoData.enobrecimento_acabamento.trim() !==
                            "" && (
                            <MDBCol md="6" className="custom-select mb-3">
                              <label>
                                <IoDiamondOutline /> Escolha o Acabamento
                              </label>
                              <select
                                value={selectedAcabamento}
                                onChange={(e) =>
                                  setSelectedAcabamento(e.target.value)
                                }
                                className="custom-input"
                              >
                                <option value="">
                                  Selecione o acabamento ideal
                                </option>
                                {produtoData.enobrecimento_acabamento
                                  .split(",")
                                  .map((acabamentoItem, index) => (
                                    <option
                                      key={index}
                                      value={acabamentoItem.trim()}
                                    >
                                      {acabamentoItem.trim()}
                                    </option>
                                  ))}
                              </select>
                            </MDBCol>
                          )}
                      </MDBRow>

                      <MDBRow className="w-100">
                        <MDBCol md="12" className="custom-select mb-3">
                          <label>
                            <FiShoppingCart /> Quantidade Desejada
                          </label>
                          <select
                            value={selectedQuantidade}
                            onChange={(e) =>
                              setSelectedQuantidade(e.target.value)
                            }
                            className="custom-input"
                          >
                            {[100, 250, 500, 1000, 2000, 5000, 10000].map(
                              (quantidade, index) => (
                                <option key={index} value={quantidade}>
                                  {quantidade} unidades
                                </option>
                              )
                            )}
                          </select>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="w-100">
                        <MDBCol md="6" className="custom-select mb-3">
                          <label>
                            <AiOutlineUser /> Seu Nome Completo
                          </label>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="custom-input"
                            placeholder="Digite seu nome"
                          />
                        </MDBCol>

                        <MDBCol md="6" className="custom-select mb-3">
                          <label>
                            <AiOutlineMail /> Seu E-mail
                          </label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="custom-input"
                            placeholder="Digite seu e-mail"
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="w-100">
                        <MDBCol md="6" className="custom-select mb-3">
                          <label>
                            <AiOutlinePhone /> Seu Telefone
                          </label>
                          <input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="custom-input"
                            placeholder="Digite seu telefone"
                          />
                        </MDBCol>

                        <MDBCol
                          md="6"
                          className="custom-select mb-3 d-flex align-items-center"
                        >
                          <input
                            type="checkbox"
                            checked={subscribeNewsletter}
                            onChange={(e) =>
                              setSubscribeNewsletter(e.target.checked)
                            }
                          />
                          <label className="ms-2">
                            Desejo receber novidades e ofertas exclusivas
                          </label>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="produto-button-wrapper w-100 mt-3">
                        <MDBCol md="6">
                          <MDBBtn
                            className="email w-100"
                            onClick={handleEmail}
                          >
                            <LuMailCheck/> Solicitar por E-mail
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBBtn
                            className="whatsapp w-100"
                            onClick={handleWhatsApp}
                          >
                            <IoLogoWhatsapp /> Solicitar no WhatsApp
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="shape-bottom">
        <img src={DividerImage} alt="Divisão da Seção" className="img-fluid" />
      </div>
    </>
  );
};

export default TopSection;
