import React from 'react';
import FirstSection from '../components/about/firstsection';
import SecondSection from '../components/about/secondsection';
import ThirdSection from '../components/about/thirdsection';
import Contato from '../components/index/contato';
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from '../components/navbar'
import Footer from '../components/footer'

import '../styles/about.css'

const about = () => {
  return (
    <MDBContainer fluid className='about-container'>
      <Navbar />
      <FirstSection />
      <SecondSection />
      <Contato />
      <Footer />
    </MDBContainer>
  );
};

export default about;
