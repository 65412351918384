import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText,
  MDBBtn,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import "../../styles/hero.css";
import { api } from "../../controllers/api-controller";
import { BsSendArrowUp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Riple } from "react-loading-indicators";

const Hero = () => {
  const [categories, setCategories] = useState([]); // Estado para armazenar as categorias
  const [searchQuery, setSearchQuery] = useState(""); // Estado para armazenar o input do usuário
  const [searchResults, setSearchResults] = useState([]); // Estado para armazenar os resultados da pesquisa
  const [loading, setLoading] = useState(false); // Estado para controlar o loader
  const [error, setError] = useState(false); // Estado para controlar o erro

  // Função para buscar as categorias da API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${api}/categorias/get`);
        const data = await response.json();
        setCategories(data.categorias || []);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    fetchCategories();
  }, []);

  // Função para realizar a pesquisa de produtos
  const handleSearch = async () => {
    if (searchQuery.length < 3) {
      setSearchResults([]);
      return; // Evita a busca se o input for muito curto
    }

    // Começa o processo de busca, ativa o loader e reseta os estados de erro e resultados
    setLoading(true);
    setError(false);
    setSearchResults([]);

    try {
      const response = await fetch(`${api}/produtos/iasearch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userInput: searchQuery }), // Envia o input do usuário
      });
      const data = await response.json();

      // Verifica se a resposta contém "not_found"
      if (data.message === "not_found") {
        setError(true); // Ativa o estado de erro
      } else {
        setSearchResults(data.produtos || data || []); // Define os resultados da pesquisa no estado
      }
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
      setError(true); // Ativa o estado de erro se houver uma falha na requisição
    } finally {
      setLoading(false); // Desativa o loader após a busca
    }
  };

  return (
    <section>
      <MDBRow className="justify-content-center align-items-center ai-row">
        <MDBCol md={6} className="ai-onboarding-col">
          <h1>Encontre a solução perfeita para o seu negócio</h1>
          <p>
            É muito fácil: basta descrever o que seu negócio precisa e, com um
            clique no botão, nossa IA irá apresentar as melhores opções para
            você!
          </p>
        </MDBCol>
        <MDBCol
          md={8}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="ai-inputhero">
            <div className="input-pesquisar">
              <input
                type="text"
                placeholder="Conte um pouco sobre seu negócio ou objetivo de campanha..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Atualiza o input do usuário
              />
              <MDBBtn onClick={() => handleSearch()}>
                <BsSendArrowUp size={25} />
              </MDBBtn>
            </div>
          </div>
        </MDBCol>

        {/* Se estiver carregando, exibe o loader */}
        {loading ? (
          <MDBCol md={8} className="pb-3 pt-5 text-center">
            <Riple color="#3150cc" size="medium" text="" textColor="" />
          </MDBCol>
        ) : error ? (
          <MDBCol md={8} className="pb-3 pt-5">
            <p className="text-center ia-error">
              Não encontramos produtos correspondentes à sua pesquisa.
            </p>
          </MDBCol>
        ) : searchResults.length > 0 && (
          <MDBCol md={8} className="pb-3 pt-5">
            {/* Renderiza os resultados da pesquisa abaixo da barra de pesquisa */}
            <MDBRow className="mt-4">
              {searchResults.map((product, index) => (
                <MDBCol md="4" key={index} className="product-col">
                  <MDBCard className="produtos-card-ai">
                    <MDBCardBody>
                      <MDBCardImage
                        src={`${api}/${product.url_imagem}`} // Substitua pela URL correta para as imagens
                        alt={product.nome}
                        className="product-img img-fluid"
                      />
                      <MDBCardTitle className="produto-title">
                        {product.nome}
                      </MDBCardTitle>
                      <Link to={`/produto/${product.slug}`}>
                        <span className="produtos-btn">
                          Quero Conhecer <IoChevronForwardSharp size={20} />
                        </span>
                      </Link>
                      <span className="product-tags">
                        Tags: {product.tags}.
                      </span>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBCol>
        )}
      </MDBRow>

      <MDBRow className="cards-horizontal d-flex justify-content-center pt-5 mt-5">
        <MDBCol md={5} className="categorias-onboarding-col">
          <h1>Transforme suas ideias em soluções visuais impactantes!</h1>
          <p>
            Na PDV em Foco, nossa missão é dar vida às suas ideias com soluções
            visuais personalizadas e de alta qualidade. Com vasta experiência no
            mercado gráfico, ajudamos empresas de todos os portes a se
            destacarem em suas campanhas de marketing e comunicação por meio de
            materiais promocionais, brindes corporativos e comunicação visual
            que realmente fazem a diferença.
            <br /> <br />
            Descubra como podemos elevar a sua marca e atrair mais resultados
            para o seu negócio!
          </p>
        </MDBCol>
        <MDBCol md={6} className="justify-content-start row">
          <div className="cards-horizontal row justify-content-center">
            {categories.map((category, index) => (
              <MDBCol
                key={index}
                md={4}
                className="mb-4 text-center card-wrapper"
              >
                <a href={`/categorias/${category.slug}`}>
                  <MDBCard className="hero-card mb-3">
                    <MDBCardImage
                      src={`${api}/${category.url_imagem}`}
                      alt={`Imagem de ${category.nome}`}
                      position="top"
                    />
                    <p className="mt-4">{category.nome}</p>
                  </MDBCard>
                </a>
              </MDBCol>
            ))}
          </div>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default Hero;
