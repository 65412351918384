import React, { useState } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import "../styles/navbar.css"; // Estilos da Navbar
import LogoPdv2 from "./svgs"; // Sua logo
import Modal from "./modal"; // Importar o modal
import { RiBuilding2Line } from "react-icons/ri";
import { CgMenuRound } from "react-icons/cg";
import { RiHomeLine } from "react-icons/ri";
import { LuGalleryHorizontal } from "react-icons/lu";
import { ImWhatsapp } from "react-icons/im";
import { HiOutlineSquaresPlus } from "react-icons/hi2";
import { RiGift2Line } from "react-icons/ri";
import { TbBroadcast } from "react-icons/tb";
import { FiShoppingCart } from "react-icons/fi";
import { MdDesignServices } from "react-icons/md";
import { AiOutlineExperiment } from "react-icons/ai";
import { HiOutlineViewGrid } from "react-icons/hi";

export default function App() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  // Categorias com ícones modernos e descrições
  const categorias = [
    {
      nome: "Brindes",
      descricao: "Personalize a experiência com brindes únicos e criativos.",
      link: "/categorias/brindes",
      icon: <RiGift2Line size={24} />,
      color: "#FF5733",
    },
    {
      nome: "Comunicação",
      descricao: "Amplifique sua marca com soluções de comunicação inovadoras.",
      link: "/categorias/comunicacao",
      icon: <TbBroadcast size={24} />,
      color: "#33C1FF",
    },
    {
      nome: "MPDV",
      descricao:
        "Impulsione as vendas com materiais estratégicos e impactantes no ponto de venda.",
      link: "/categorias/material-de-ponto-de-venda",
      icon: <FiShoppingCart size={24} />,
      color: "#FFC300",
    },
    {
      nome: "Material Gráfico",
      descricao: "Eleve seus projetos com design gráfico de alta qualidade.",
      link: "/categorias/material-grafico",
      icon: <MdDesignServices size={24} />,
      color: "#8E44AD",
    },
    {
      nome: "Projetos Especiais",
      descricao:
        "Soluções criativas e personalizadas para necessidades exclusivas.",
      link: "/categorias/projetos-especiais",
      icon: <AiOutlineExperiment size={24} />,
      color: "#2ECC71",
    },
  ];

  return (
    <>
      <MDBNavbar expand="lg" className="navbar-custom">
        <MDBContainer fluid className="navbar-container">
          {/* Logo à esquerda */}
          <MDBNavbarBrand href="#">
            <LogoPdv2 />
          </MDBNavbarBrand>

          {/* Itens de navegação alinhados à direita */}
          <div className="d-flex justify-content-end align-items-center w-75">
            <MDBNavbarNav className="navbar-nav-right mobile-hidden">
              <MDBNavbarItem>
                <MDBNavbarLink aria-current="page" href="/">
                  <RiHomeLine /> <span className="nav-link-text">Início</span>
                </MDBNavbarLink>
              </MDBNavbarItem>

              {/* Produtos - Dropdown Controlado */}
              <MDBNavbarItem className="dropdown-container">
                <MDBNavbarLink className="dropdown-label">
                  <HiOutlineSquaresPlus />{" "}
                  <span className="nav-link-text">Produtos</span>
                </MDBNavbarLink>

                {/* Dropdown com categorias e novos ícones */}
                <div className="products-dropdown">
                  <a
                    href="/categorias"
                    className="menu-item"
                    style={{ color: "#000" }}
                  >
                    <HiOutlineViewGrid size={24} className="animated-icon" />
                    <span
                      style={{ color: "#000" }}
                      className="dropdown-menu-item-text"
                    >
                      Todos
                    </span>
                  </a>
                  {categorias.map((categoria, index) => (
                    <a
                      href={categoria.link}
                      className="menu-item"
                      key={index}
                      style={{ color: categoria.color }} // Aplica a cor personalizada
                    >
                      {/* Ícone moderno para cada categoria */}
                      {categoria.icon}
                      <span className="text-container">
                        <span
                          style={{ color: categoria.color }}
                          className="nome"
                        >
                          {categoria.nome}
                        </span>
                        {/* Adicionando a descrição */}
                        <p className="descricao">
                          {categoria.descricao}
                        </p>
                      </span>
                    </a>
                  ))}
                </div>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink href="/galeria">
                  <LuGalleryHorizontal />{" "}
                  <span className="nav-link-text">Galeria</span>
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink href="/pdv">
                  <RiBuilding2Line />{" "}
                  <span className="nav-link-text">Sobre nós</span>
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink href="/#orcamento" className="contact-btn">
                  <ImWhatsapp />{" "}
                  <span className="nav-link-text ">Faça sua cotação </span>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>

            {/* Menu responsivo para dispositivos menores */}
            <MDBBtn
              className="menu-btn desktop-hidden ms-2"
              color="primary"
              onClick={toggleModal}
            >
              <CgMenuRound />
            </MDBBtn>
          </div>
        </MDBContainer>
      </MDBNavbar>

      {/* Modal de navegação para dispositivos móveis */}
      <Modal show={showModal} handleClose={toggleModal}>
        <div className="modal-content">
          <MDBNavbarNav className="navbar-nav-modal">
            <MDBNavbarBrand href="#" className="modal-logo navbar-brand-modal">
              <LogoPdv2 />
            </MDBNavbarBrand>
            <MDBNavbarItem>
              <MDBNavbarLink aria-current="page" href="/">
                Início
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/categorias">Produtos</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/galeria">Galeria</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/sobrenos">Sobre nós</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBBtn
              color="primary"
              className="contact-btn mt-4 modal-contact-btn"
            >
              Entre em Contato
            </MDBBtn>
          </MDBNavbarNav>
        </div>
      </Modal>
    </>
  );
}
