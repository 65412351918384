import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "../../styles/sobreNos.css";
import { GoArrowRight } from "react-icons/go";
import AboutIcon from "../../img/about/about-icon.png";

const SobreNos = () => {
  return (
    <MDBContainer fluid className="sobre-nos-section">
      <MDBRow className="align-items-center justify-content-center sobre-nos-row">
        <MDBCol md="5" className="sobre-img">
          <img src={AboutIcon} alt="Sobre Nós" className="img-fluid" />
        </MDBCol>
        <MDBCol md="6" className="sobre-text-wrapper sobre-text-content p-5">
          <h2>Transformando ideias em soluções</h2>
          <p>
            A PDV em Foco é uma empresa especializada em materiais promocionais
            personalizados, com ampla experiência na produção de brindes,
            materiais de ponto de venda (PDV) e itens impressos para destacar
            empresas em suas campanhas de marketing. Garantimos produtos de alta
            qualidade, com entregas rápidas e eficientes, além de um atendimento
            personalizado para atender às necessidades específicas de cada
            cliente.
          </p>
          <a href="/pdv">Conheça mais sobre nós <GoArrowRight size={20} /></a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SobreNos;
