import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBBtn,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { IoChevronForwardSharp } from "react-icons/io5";
import "../../styles/produtos.css";
import Top from "../../img/index/produtos-top.png";
import Bot from "../../img/index/produtos-bot.png";
import { api } from "../../controllers/api-controller"; // Certifique-se de ajustar o caminho para a API
import { HiOutlineSquaresPlus } from "react-icons/hi2";

const Produtos = () => {
  const [activeTab, setActiveTab] = React.useState("all"); // Aba ativa
  const [produtosData, setProdutosData] = useState([]); // Produtos da API
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Função para buscar produtos da API
  const fetchProdutos = async () => {
    try {
      const response = await fetch(`${api}/produtos`); // Chamada para sua API
      const data = await response.json();
      setProdutosData(data.produtos); // Armazena os produtos da API
    } catch (err) {
      setError("Erro ao carregar os produtos"); // Armazena erro
    } finally {
      setLoading(false); // Desativa o carregamento
    }
  };

  // useEffect para carregar os produtos ao montar o componente
  useEffect(() => {
    fetchProdutos();
  }, []);

  // Filtro dos produtos de acordo com a aba ativa e limitando a exibição para 6 itens
  const filteredProducts =
    activeTab === "all"
      ? produtosData.slice(0, 6) // Exibe os primeiros 6 produtos
      : produtosData
          .filter((product) => product.categoria_nome === activeTab)
          .slice(0, 6); // Exibe os primeiros 6 da categoria

  // Verifica se está carregando
  if (loading) {
    return <div>Carregando produtos...</div>;
  }

  // Verifica se houve erro
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {/* Shape Superior */}
      <div className="shape-top">
        <img src={Top} alt="Shape Top" className="img-fluid" />
      </div>

      {/* Seção de Produtos */}
      <MDBContainer fluid className="produtos-section">
        <h2 className="text-center my-4">O impacto que sua marca precisa</h2>
        <h4 className="text-center mb-4">
          Nossos produtos são a solução ideal para seu negócio
        </h4>

        <MDBRow className="justify-content-center">
          <MDBCol md={11}>
            {/* Tabs de categorias */}
            <MDBTabs pills className="mb-3 justify-content-center">
              <MDBTabsItem>
                <MDBTabsLink
                  className="produtos-tab"
                  onClick={() => handleTabClick("all")}
                  active={activeTab === "all"}
                >
                  Todos
                </MDBTabsLink>
              </MDBTabsItem>
              {/* Gerar abas dinamicamente com base nas categorias disponíveis */}
              {[
                ...new Set(
                  produtosData.map((produto) => produto.categoria_nome)
                ),
              ].map((category, index) => (
                <MDBTabsItem key={index}>
                  <MDBTabsLink
                    className="produtos-tab"
                    onClick={() => handleTabClick(category)}
                    active={activeTab === category}
                  >
                    {category}
                  </MDBTabsLink>
                </MDBTabsItem>
              ))}
            </MDBTabs>
          </MDBCol>
        </MDBRow>

        {/* Renderizar produtos filtrados */}

        <MDBRow className="justify-content-center product-gen-container">
          <MDBCol md={10} className="row">
            {filteredProducts.map((produto) => (
              <MDBCol md="4" key={produto.id} className="product-col">
                <MDBCard className="produtos-card">
                  <MDBCardBody>
                    <MDBCardImage
                      src={`${api}/${produto.url_imagem}`} // Substitua pela URL correta para as imagens
                      alt={produto.nome}
                      className="product-img img-fluid"
                    />
                    <MDBCardTitle className="produto-title">
                      {produto.nome}
                    </MDBCardTitle>
                    <Link to={`/produto/${produto.slug}`}>
                      <span className="produtos-btn">
                        Quero Conhecer <IoChevronForwardSharp size={20} />
                      </span>
                    </Link>
                    <span className="product-tags">Tags: {produto.tags}.</span>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </MDBCol>
        </MDBRow>

        <div className="text-center">
          <MDBBtn className="all-produtos-btn" href="#">
            <HiOutlineSquaresPlus size={22}/> Mais produtos
          </MDBBtn>
        </div>
      </MDBContainer>

      {/* Shape Inferior */}
      <div className="shape-bottom">
        <img src={Bot} alt="Shape Bottom" className="img-fluid" />
      </div>
    </>
  );
};

export default Produtos;
